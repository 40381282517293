<template lang="pug">
.signup(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  .container-fluid
    v-row.pa-0.ma-0(style="height: 100vh")
      v-col.pa-0.main_bg.d-none.d-md-flex.align-center.justify-center(
        cols="12",
        md="6"
      )
        .description
          h1 已經註冊為會員？
          p 登入GC會員帳號享有更多服務
          button.btn.login_now(@click="toLogin") 前往登入
      v-col.d-flex.pa-0.opacity_bg(cols="12", md="6", style="width: 100%")
        v-container.d-flex.align-center.justify-center.w-100
          v-row.justify-center(style="flex-shrink: 1; flex-grow: 1")
            v-col.d-flex.justify-center.align-center(cols="12")
              img.logo(:src="logo", target="_blank")
              h1.text-center.sign_up-title 註冊為會員
            v-col(cols="12", sm="8")
              form.form-login(method="post", style="margin-top: 40px")
                .input_container.d-flex(
                  :class="[{ waiting: !sendPhoneCodeEnable }, { successing: verifyStatus == 2 }]"
                )
                  label.sr-only(for="id_mobile") 手機號碼
                  select.code(v-model="mobileCode", ref="codeselect")
                    option(v-for="(code, index) in codeList", :key="index") {{ code }}
                  input#id_mobile(
                    ref="mobileinput",
                    required,
                    type="tel",
                    name="mobile",
                    :placeholder="'範例: ' + phoneExample[mobileCode]",
                    autofocus="",
                    v-model="mobile"
                  )
                  svg-icon(
                    iconClass="mobile_hollow",
                    className="mobile_hollow"
                  )
                  .send_verify_code_btn(@click="sendPhoneCode") {{ getSendPhoneCodeBtnText }}
                .input_container.d-flex.mt-6
                  label.sr-only(for="id_pin_code") 請輸入驗證碼
                  input#id_pin_code(
                    required,
                    type="text",
                    name="phoneCode",
                    placeholder="請輸入驗證碼",
                    autofocus="",
                    v-model="phoneCode"
                  )
                .input_container.mt-6
                  label.sr-only(for="id_username") 會員Email帳號
                  input#id_username(
                    required,
                    type="email",
                    name="email",
                    placeholder="會員 Email 帳號",
                    autofocus="",
                    v-model="email"
                  )
                  svg-icon(iconClass="user_hollow", className="user_hollow")
                .input_container.mt-6
                  label.sr-only(for="id_password") 設定會員密碼
                  input#id_password(
                    required,
                    type="password",
                    name="password",
                    placeholder="設定會員密碼",
                    v-model="password"
                  )
                  svg-icon(iconClass="key_hollow", className="key_hollow")
                .input_container.mt-6
                  label.sr-only(for="id_password_confirm") 再次輸入密碼
                  input#id_password_confirm(
                    required,
                    type="password",
                    name="password_repeat",
                    placeholder="再次輸入密碼",
                    v-model="passwordRepeat"
                  )
                  svg-icon(iconClass="key_hollow", className="key_hollow")
                .btn.sign_up.w-100(
                  :ripple="false",
                  style="margin-top: 36px",
                  @click="register"
                ) 註冊
                .or.text-center
                  span OR
                .btn.login(@click="toLogin") 前往登入
            v-col.d-flex.justify-center.align-center(cols="12")
              v-footer(align="center")
                .footer
                  h5 Copyright © 2022 競晰股份有限公司
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import Logo from "@/assets/img/sign_up/logo.png";
import { register, sendPhoneCode } from "@/api/user";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";
import { validEmail } from "@/utils/validate";

export default {
  name: "SignUp",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      logo: Logo,
      mobileCode: "+886",
      mobile: "",
      phoneCode: "",
      email: "",
      password: "",
      passwordRepeat: "",
      codeList: ["+886", "+852", "+86"],
      countryDict: {
        "+886": "TW",
        "+852": "HK",
        "+86": "CHINA",
      },
      phoneExample: {
        "+886": "0912345678",
        "+852": "91234567",
        "+86": "13123456789",
      },
      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "會員註冊 | Game Changer",
        description: "會員註冊",
        keywords: "Game changer,籃球,會員,會員註冊",
        url: "https://www.gamechanger.tw/register",
        image: "",
        smoType: "website",
      },
      sendVerifyCountdown: null,
      sendPhoneCodeEnable: true, //是否可傳送驗證碼
      verifyStatus: 0, //0: 預設 1:驗證碼已發送過 2:驗證成功
      countdown: 180,
    };
  },
  methods: {
    register() {
      if (
        this.email == "" ||
        this.password == "" ||
        this.passwordRepeat == ""
      ) {
        this.showErrorDialog("資料錯誤", "請確認帳號密碼是否填入");
        return;
      }
      if (this.password !== this.passwordRepeat) {
        this.showErrorDialog("資料錯誤", "請確認密碼及再次確認是否相同");
        return;
      }
      if (!validEmail(this.email)) {
        this.showErrorDialog("帳號錯誤", "信箱格式錯誤");
        return;
      }

      if (this.mobile == "") {
        this.showErrorDialog("資料錯誤", "請確認手機號碼是否填入");
        return;
      }

      if (this.phoneCode == "") {
        this.showErrorDialog("資料錯誤", "請確認驗證碼是否填入");
        return;
      }

      if (this.phoneCode.length != 6) {
        this.showErrorDialog("資料錯誤", "驗證碼為6碼格式");
        return;
      }

      this.showLoadingDialog("資料傳送中");
      let inputData = {
        email: this.email,
        password: this.password,
        phone: this.mobileCode + this.mobile,
        phone_code: this.phoneCode,
      };
      register(inputData)
        .then(() => {
          this.closeLoadingDialog();
          this.showSuccessDialog(
            "已發送認證信",
            "請於信箱中收信，認證您的帳號(信件有可能在垃圾郵件，再麻煩檢查！)"
          );
          this.resetInputData();
          setTimeout(() => {
            this.closeSuccessDialog();
            this.$router.push({ name: "Home" });
          }, 5000);
        })
        .catch((error) => {
          this.closeLoadingDialog();
          this.showErrorDialog("註冊失敗", error.response.data.error_msg);
        });
    },
    resetInputData() {
      this.mobile = "";
      this.mobileCode = "";
      this.phoneCode = "";
      this.email = "";
      this.password = "";
      this.passwordRepeat = "";
    },
    sendPhoneCode() {
      if (this.mobileCode == "" || this.mobile == "") {
        this.showErrorDialog("錯誤", "請填寫正確手機號碼");
        return;
      }

      if (!this.sendPhoneCodeEnable) {
        return;
      }

      let inputData = {
        phone: this.mobileCode + this.mobile,
        country: this.countryDict[this.mobileCode],
      };

      sendPhoneCode(inputData)
        .then((response) => {
          console.log(response.data);
          this.sendPhoneCodeEnable = false;
          this.verifyStatus = 1; //0: 預設 1:驗證碼已發送過 2:驗證成功
          var self = this;

          this.sendVerifyCountdown = setInterval(() => {
            if (self.countdown == 0) {
              clearInterval(this.sendVerifyCountdown);

              self.sendPhoneCodeEnable = true;
              self.countdown = 30;
            } else {
              self.countdown = self.countdown - 1;
            }
          }, 1000);
        })
        .catch((error) => {
          this.showErrorDialog("發送失敗", error.response.data.error_msg);
          return;
        });
    },
    toLogin() {
      this.$router.push({ name: "Login" });
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
  computed: {
    getSendPhoneCodeBtnText() {
      if (this.verifyStatus == 2) {
        return "驗證成功";
      } else if (this.sendPhoneCodeEnable) {
        return "發送簡訊";
      } else {
        return "重新發送(" + this.countdown + ")";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/user/register";
</style>
